@import "../index.scss";

#header {
    width: 100vw;
    text-align: left;
    padding: 0;
    margin: 0;


    #abstract {
        position: absolute;
        top: 0;
        z-index: 0;
        width: 80vw;
        object-fit: cover;
        opacity: 0.3;

    }

    #top-row {
        // position: absolute;
        // z-index: 2;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2.0rem 5vw;
        width: 90vw;

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        #left-side {
            position: relative;
            z-index: 3;
            width: fit-content;


            .logo-box {
                z-index: 10;
                position: relative;
                // opacity: 1.0;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-items: flex-start;
                margin-top: 2rem;

                img {
                    height: 15vw;
                }

                h1 {
                    margin-top: 1vw;
                    margin-left: 1.0rem;
                    font-size: 4rem;
                    color: white;
                    white-space: wrap;
                    
                    @media screen and (max-width: 1200px) {
                        font-size: 3.2rem;
                    }

                }
            }

            .mission {
                position: relative;
                left: 2vw;
                color: $blue;
                font-size: 2.5rem;
                letter-spacing: 0.2rem;
                // width: 50vw;
                max-width: 800px;
                margin: 2rem;

                @media screen and (max-width: 1200px) {
                    font-size: 2rem;
                    margin: 0;
                    left: 0;

                    @media screen and (max-width: 991px) {
                        width: 90vw;
                    }
                }

                .goal {
                    position: relative;
                    left: 0.25rem;
                    border-left: $radius solid $blue;
                    border-radius: 0;
                    padding-left: 1.0rem;
                    font-size: 1.2rem;
                    color: white;
                }
            }

            #stage-banner {
                position: relative;
                bottom: -3rem;
                margin: 4.0rem 0 0 -5vw;
                // margin-bottom: -2rem;
                width: fit-content;
                background-color: $t-black;
                color: $blue;
                padding: 0.5rem 2.0rem 1.0rem 4.0rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 4px solid $blue;
                border-left: none;

                animation: border-pulse-blue 3s infinite;

                h3 {
                    font-size: 1.7rem;
                    margin: 0.2rem;
                }

                h4 {
                    color: whitesmoke;
                    text-align: right;
                    font-size: 1.1em;
                    margin: 0.5rem;
                }

                #primary {
                    background-color: $red;
                    border: 2px solid transparent;
                    color: white;
                    margin: 0 0.5rem;

                    &:hover {
                        background-color: $t-black;
                        color: $blue;
                    }
                }

                #secondary {
                    border: 2px solid $t-white;
                    color: white;
                    margin: 0 0.5rem;

                    &:hover {
                        border: 2px solid transparent;
                        background-color: $t-black;
                    }
                }

            }
        }

        .demo-box {
            position: relative;
            z-index: 2;
            top: 5vh;
            // right: 5vw;
            width: 40vw;
            max-width: 600px;
            // height: 30vw;
            text-align: center;
            padding: 1.0rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // overflow: hidden;
            
            @media screen and (max-width: 991px) {
                width: 90vw;
                margin: auto;
            }

            #demo-prompt {
                z-index: 5;
                position: absolute;
                top: 40%;
                right: 20%;
                background-color: $t-black;
                color: white;
                padding: 0.5rem 1rem;
                display: flex;
                animation: border-pulse-blue 2s infinite;
                border: 2px solid transparent;
                font-size: 1.4rem;

                #icon {
                    width: 2.0rem;
                    height: 2.0rem;
                }
            }



            a {
                // box-shadow: 10px 15px $t-black;
                text-decoration: none;
                height: 50vh;
                display: flex;
                // flex: 1 1 100%;  
                // overflow: hidden; 
                object-fit: cover;
                margin: auto;

                .image-wrapper {
                    // overflow: hidden; 
                    object-fit: cover;
                    height: 50vh;
                    border-radius: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;

                        /*Inline Styles Calculated in JavaScript
                        position
                        opacity
                        Z-Index
                        Scale
                        */
                    }
                }

            }



            #demo-menu {
                position: relative;
                z-index: 4;
                display: flex;
                flex-direction: row;
                margin: 1.0rem auto;
                background-color: $red;

                button {
                    color: $t-black;
                    letter-spacing: 0.1rem;
                }

                .active {
                    color: $blue;
                }

                .divider-right {
                    border-radius: 0;
                    border-right: 1px solid whitesmoke;
                }
            }
        }
    }

    #cards-box {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: row;
        // align-items: stretch;
        justify-content: space-between;
        
        margin: 2.0rem;
        margin-top: 10vh;

        @media screen and (max-width: 1200px) {
            margin-top: 10vh;
            flex-direction: column;
        }

        .card {
            max-width: 400px;
            margin: 0 1.0rem;

            border-radius: 1.75rem;
            background-color: black;
            overflow: hidden;
            display: flex;
            flex: 1 1 100%;

            @media screen and (max-width: 1200px) {
                margin: 3vh auto;
            }

            .description {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 1.2rem;
                line-height: 1.4em;
                letter-spacing: 0.08em;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .description-inside {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 3.0rem 1.5rem;
                    opacity: 0;

                    .header {
                        color: $blue;
                        text-align: left;
                        margin-bottom: 2.0rem;
                        font-size: 1.1em;
                    }

                    .footer {
                        color: $blue;
                        text-align: right;
                        margin-top: 1.0rem;
                        font-size: 0.8em;
                    }
                }

                .prompt {
                    position: relative;
                    z-index: 4;
                    bottom: 25%;
                    padding: 0.5rem;
                    margin-top: 2.0rem;
                }

                .prompt-footer {
                    position: relative;
                    bottom: 0;
                    border-top: 2px solid $blue;
                    border-radius: 0;
                    color: $blue;
                    padding: 0.5rem;
                    margin-top: 2.0rem;
                    margin-bottom: 1.0rem;
                }

            }

            img {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin: auto;
            }
        }
    }

    .menu-box {
        position: absolute;
        z-index: 10;
        top: 1rem;
        right: 2rem;

        a,
        button {
            color: white;
            text-decoration: none;
            font-size: 1.5rem;
            padding: 0.5rem 1.0rem;
            margin: auto;
        }

        a:hover {
            color: $blue;
            text-decoration: underline;
            text-decoration-color: $blue;
            cursor: pointer;
        }

        button {
            background-color: $blue;
            color: white;

            &:hover {
                color: $blue;
                background-color: white;
                cursor: pointer;
            }
        }


        @media screen and (max-width: 575px) {
            .hide-mobile {
                display: none;
            }
        }

    }

    #shape-rectangle {
        
        background-color: $red;
        border-radius: 0;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
    }

    #shape {
        @extend #shape-rectangle;
        position: absolute;
        background-color: transparent;
        overflow: hidden;
        margin-top: -5px;

        svg {
            height: 100%;
            width: 100%;

            path {
                stroke: none;
                fill: $red;
            }
        }
    }
}
@import "../index.scss";

#footer {
    position: relative;
    margin-top: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: baseline;
    align-items: flex-start;

    #wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        justify-items: flex-start;
        width: 100%;
        margin-top: auto;

        position: relative;
        z-index: 2;

        a {
            text-decoration: none;
            margin-bottom: auto;

            #pew35-logo {
                width: 10vw;
                min-width: 150px;
                object-fit: cover;
                object-position: center;

                @media screen and (max-width: 991px) {
                    min-width: 100px;
                }
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;

            a{
                margin-bottom: 0;
            }
        }

        #references {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 1.5rem;

            @media screen and (max-width: 991px) {
                margin-top: 0;
            }

            h3 {
                font-size: 1.8rem;
                color: $blue;
            }

            a {
                text-decoration: none;

                h4 {
                    color: whitesmoke;
                    margin: 0.15rem;
                }

                &:hover {
                    h4 {
                        color: $blue;
                    }
                }
            }
        }
    }

    #pew35-slogan {
        z-index: 2;
        bottom: 1.0rem;

        height: 100px;
        max-width: 90vw;
        object-fit: contain;
        object-position: center;
    }

    #footer-background {
        position: absolute;
        // height: 40vh;

        //Height of #footer-rectangle and #footer-shape need to add to Height of #footer
        #footer-rectangle {
            position: relative;
            background-color: $red;
            border-radius: 0;
            z-index: 0;
            bottom: 0;
            width: 100vw;
            height: 250px;
        }

        #footer-shape {
            @extend #footer-rectangle;
            z-index: 1;
            background-color: transparent;
            overflow: hidden;
            height: 25vh;

            svg {
                height: 100%;
                width: 100%;

                path {
                    stroke: none;
                    fill: $red;
                }
            }
        }
    }
}
@import "../index.scss";

#feedback-submitted {
    width: 100vw;
    max-width: min(700px, 90vw);
    text-align: center;
    margin: auto;
    padding: 0.5rem;

    h3 {
        margin: auto;
        width: fit-content;
        padding: 0.75rem 1.25rem;
        // background-color: $t-black;
        color: $blue-dark;

        
    }

    &.fail {
        h3 {
            color: $red;
        }

        h5 {
            color: $blue-dark;
            font-size: 1.0rem;
        }

        div {
            display: flex;
            justify-content: space-around;
            flex-direction: row;

            strong {
                font-size: 3rem;
                color: $red;
            }

            img {
                height: 2.5rem;
            }
        }
    }

    //Outline Animation
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, $blue 100%, $blue 100%),
        linear-gradient(to bottom, $blue 100%, $blue 100%),
        linear-gradient(to right, $blue 100%, $blue 100%),
        linear-gradient(to bottom, $blue 100%, $blue 100%);
    background-position: 0 0,
        100% 0,
        100% 100%,
        0 100%;

    animation: trace 4s cubic-bezier(0.19, 1, 0.22, 1) infinite;
}

#feedback, #subscribe {
    position: relative;
    margin: 0 5vw;
    max-width: 1300px;
    text-align: left;

    &#subscribe {
        max-width: 800px;
        margin-right: auto;
    }

    @media screen and (max-width: 991px) {
        max-width: 90vw;
    }

    h2 { //Section Header
        color: $red;
    }

    h3 { //Name
        color: $blue-dark
    }

    label {
        font-size: 1.0rem;
        font-weight: 700;
        letter-spacing: 0.085rem;
        color: $blue-dark;
    }

    #prayer-wrapper {
        height: fit-content;
        width: 25%;
        // background-color: $t-black;
        // padding: 1.5rem;
        margin: 0 1.0rem 1.0rem;
        text-align: center;

        

        label {
            color: $blue-dark;
        }

        textarea {
            width: calc(100% - 2.0rem);
            // background-color: $t-white;
            height: 30vh;
            border: none;
            margin-top: 1.0rem;
            padding: 1.0rem;

            //Outline Animation
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, $blue 100%, $blue 100%),
            linear-gradient(to bottom, $blue 100%, $blue 100%),
            linear-gradient(to right, $blue 100%, $blue 100%),
            linear-gradient(to bottom, $blue 100%, $blue 100%);
        background-position: 0 0,
            100% 0,
            100% 100%,
            0 100%;

        animation: trace 5s cubic-bezier(0.19, 1, 0.22, 1) infinite;
        }
    }

    @media screen and (max-width: 991px) {
        flex-direction: column;
        // align-items: center;

        #prayer-wrapper {
            width: 90%;
            max-height: 100px;
            padding: 1rem;
            margin: 0 0 0;

            .label-box {
                text-align: left;
            }

            textarea {
                height: 100%;
            }
        }
    }


    .submit-box {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;

        #submit {
            color: white;
            background-color: $red;
            margin-left: 1.5rem;
            width: 10rem;

            &:hover {
                background-color: $blue;
            }
        }

        #cancel-button {
            background-color: transparent;
            border: 1px solid $blue;
            color: $blue;
            width: 10rem;
            
            &:hover {
                color: white;
                background-color: $blue;
            }
        }

        #confirmation {
            color: $red;
            margin-right: auto;
        }
    }

    .input-box {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;

        .label-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: space-between;

            strong {
                font-family: 'Roboto';
                font-weight: 300;
                font-size: 0.65rem;
                letter-spacing: 0.05rem;
                color: $t-black;
                margin: 0 1.0rem;
                
            }

            .required {
                color: $red;
                font-weight: 600;
            }
        }
    }

    input,
    select,
    textarea {
        color: black;
        border: 1px solid $red;
        border-radius: $radius;
        background-color: transparent;
        padding: 0.4rem 0.7rem;
    }

    textarea {
        height: 3.0rem;
    }



    .input-select,
    .input-option {
        .options-box {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;

            h4 {
                flex: 1 1 2%;

                font-size: 1.0rem;
                color: $t-black;
                white-space: nowrap;
                text-align: center;
                padding: 0.3rem 0.75rem;
                border: 1px solid $red;
                margin: 0 0.5rem 0.5rem 0.5rem;
                width: fit-content;

                @media (hover: hover) {
                    &:hover {
                        color: whitesmoke;
                        background-color: $red;
                        cursor: grab;
                    }
                }

            }

            .selected {
                color: whitesmoke;
                background-color: $red;

                @media (hover: hover) {
                    &:hover {
                        color: black;
                        background-color: $t-white;
                    }
                }
            }
        }

    }


    .general-questions,
    .role-questions {
        padding: 0 2.0rem 2.0rem 2.0rem;
        border: 3px solid transparent;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .general-questions {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #role-selector-box {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;

        button {
            color: $t-black;
            width: 100%;
            white-space: wrap;
            font-weight: 700;
            border-bottom: 3px solid transparent;
        }

        .selected {
            color: $red;
            border-color: $red;
            border-radius: 0;
        }
    }

    .role-questions {
        border-color: $red;
    }
}

.popup-inner-wrapper {
    #subscribe {
        margin: 0;           

        .general-questions {
            padding: 0;
            width: 100%;
            margin-right: auto;
 

            label {
                color: $blue;
            }
        }
    }
}

.horizontal-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.vertical-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 800px;
}
